import React, {useCallback, useEffect, useState} from 'react';
import Page from '../../components/page';
import {Badge, Box, Button, Card, Icon, Layout, Tabs, Text} from '@shopify/polaris';
import {ProductCost} from './product';
import {DefaultCogsMargin} from './cogsMargin';
import {TransactionCost} from './transaction';
import {HandlingCost} from './handling';
import {ShippingCost} from './shipping';
import {FixedCost} from './fixed';

const Cost = () => {
  const [selected, setSelected] = useState(0);

  const handleTabChange = useCallback(selectedTabIndex => setSelected(selectedTabIndex), []);
  const tabs = [
    {
      id: 'product',
      content: 'Product listing',
    },
    {
      id: 'gm',
      content: 'Default COGS',
    },
    {
      id: 'transaction',
      content: 'Transaction costs',
    },
    {
      id: 'handling',
      content: 'Fulfilment costs',
    },
    {
      id: 'shipping',
      content: 'Shipping costs',
    },
    {
      id: 'fixed',
      content: 'Custom fixed costs',
    },
  ];

  const renderTabContent = () => {
    switch (tabs[selected].id) {
      case 'product':
        return <ProductCost />;
      case 'gm':
        return <DefaultCogsMargin />;
      case 'transaction':
        return <TransactionCost />;
      case 'handling':
        return <HandlingCost />;
      case 'shipping':
        return <ShippingCost />;
      case 'fixed':
        return <FixedCost />;
      default:
        return null;
    }
  };

  return (
    <Page title={'Cost & expenses'}>
      <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
        <Box paddingBlockStart={'4'}>{renderTabContent()}</Box>
      </Tabs>
    </Page>
  );
};

export {Cost};
